@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(29,35,80); */
}

#root {
  height: 100vh;
}

.PhoneInputInput {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  background-image: url("./bg_image.png");
}

button[data-element="downloadButton"] {
  display: none !important;
}

.react-pdf__Page {
  max-width: 750px;
  min-width: 500px;
}

.react-pdf__Outline {
  padding-left: 30px;
  /* border-right: 5px solid rgb(203, 213, 225); */
  height: 100vh;
  overflow: auto;
  padding-bottom: 100px;
}

.react-pdf__Outline li a {
  border-bottom: 1px solid #eee;
  padding: 10px 0px;
  font-size: 14px;
  display: block;
}

.react-pdf__Outline li > ul {
  /* display: none; */
}

.react-pdf__Outline li:hover > ul {
  /* display: block; */
}

.react-pdf__message--loading {
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
}

.highlightText {
  font-weight: bold;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.textwhite:hover {
  background-color: rgb(203, 56, 51) !important;
}

.textwhite:hover .ant-dropdown-menu-submenu-title {
  color: #fff !important;
}

.textwhite:hover .ant-dropdown-menu-submenu-arrow-icon {
  color: #fff !important;
}

.textwhite:hover .ant-dropdown-menu-title-content {
  color: #fff !important;
}

.textwhite-child:hover {
  background-color: rgb(29,35,79) !important;
}

.textwhite-child:hover .ant-dropdown-menu-title-content {
  color: #fff !important;
}

.textwhite-home-child {
  padding: 5px 5px !important;
}

.textwhite-home-child:hover {
  background-color: rgb(29,35,79) !important;
}

.textwhite-home-child:hover .ant-dropdown-menu-title-content {
  color: #fff !important;
}

.deleteAccOk:hover {
  /* background: rgb(203, 56, 51) !important; */
  /* color: #fff !important; */
}